import * as React from 'react';
import { useState, useEffect, useRef } from 'react'

// MUI
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton'
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress'

// OTHER
// import {SideBarNavState} from "../components/sidebarnav"

import {
    SimpleUILogger,
} from '../simpleUI'


function Settings() {
    // const { open, toggleDrawer } = SideBarNavState ()

    const formValueDefault = {        
        enabled: false,        
        
        // CAPTEGO
        captegoEnabled: false,
        captegoTitleTemplate: "",
        captegoClientId: "",
        captegoSecretKey: "",        

        // ECONOMIC
        economicEnabled: false,
        economicAppSecretToken: "",
        economicAgreementGrantToken: "",

        // EKOMPLET
        ekompletEnabled: false,
        ekompletAPIKey: "",
        ekompletSiteName: "",

        // ORDRESTYRING
        ordrestyringEnabled: false,
        ordrestyringAPIKey: "",

        // SYNCRO
        syncroEnabled: false,
        syncroAPIKey: "",
        syncroSubdomain: "",

        // OTHER
        projectId: "",
        templateId: "",
        dataKeyId : ""
    }
                
    const [data, setData] = useState(formValueDefault)
    const [dataDefault, setDataDefault] = useState({})

    const [optionsProjectId, setOptionsProjectId] = useState([])
    const [optionsTemplateId, setOptionsTemplateId] = useState([])
    const [optionsDateKeyId, setOptionsDataKeyId] = useState([])

    const formDefault = {        
        isInitializing: true,
        disabled: false,

        titleTemplatePreview: "",
        
        saveDisabled: true,
        saveLoading: false,
        saveError: false,    
        saveErrorText: "", 

        loadingSave: false,
        loadingForceSync: false,
        
        disabledSave: true,
        disabledForceSync: false,

        disabledPreset: true,
        disabledSync: false,
        disabledDataKeyId: true,

        captegoClientIdError: false,
        captegoSecretKeyError: false,

        economicAppSecretTokenError: false,
        economicAgreementGrantTokenError: false,

        ekompletAPIKeyError: false,
        ekompletSiteNameError: false,

        ordrestyringAPIKeyError: false,

        syncroAPIKeyError: false,
        syncroSubdomainError: false,

    }

    const [connectionStatus, setConnectionStatus] = useState({
        captego: false,
        economic: false,
        ekomplet: false,
        ordrestyring: false,
        syncro: false
    })

    const [form, setForm] = useState(formDefault)

    useEffect(() => {        
        // refresh(formValueDefault) 
        fetch('/api/captegosync/settings', {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
            }        
        }) 
        .then(settings => {                
            return settings.json ()
        })    
        .then((settings) => {                            
            if (settings.error == null) {
                setDataDefault(settings)                
                setData(settings)
                refresh(settings)                
            } else {              
                throw new Error (settings.error.code)                
            }
        })       
        .catch((error) => {
            handleError (error)
        })
    }, [])

    const refresh = async (settings) => {
        setForm({...form, disabled: true, loadingSave: true}) 

        let formState = {...form}
        let respons

        respons = await fetch('/api/captegosync/settings/connectionstatus', {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
            },
        })

        setConnectionStatus(await respons.json())
       
        // if (isCaptegoConnected && isEconomicConnected) {            
        //     formState.disabledSync = false
        //     formState.disabledForceSync = false
        // } else {
        //     formState.disabledSync = true
        //     formState.disabledForceSync = true
        // }
        
        formState.isInitializing = false

        formState.disabled = false
        formState.loadingSave = false

        setForm (formState)
    }

    const handleSave = () => {
        setForm({...form, disabled: true, disabledSave: true, loadingSave: true}) 

        fetch('/api/captegosync/settings', {
            method: 'PATCH', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((data) => {
            if (data.ok) {                    
                setDataDefault(data)                                                        
                refresh (data)                
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null)
                throw new Error (data.error.code)                
        })
        .catch((error) => {       
            handleError (error)                     
        })            
    }

    const handleForceSync = () => {
        setForm({...form, disabled: true, disabledForceSync: true, loadingForceSync: true}) 
        fetch('/api/captegosync/settings/forcesync', {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
            }            
        })
        .then((data) => {
            if (data.ok) {                          
                setForm({...form, disabled: false, disabledForceSync: false, loadingForceSync: false}) 
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null)
                throw new Error (data.error.code)                
        })
        .catch((error) => {       
            handleError (error)                     
        })            
    }

    const handleError = (error) => {
        // setElement({ ...element, isInitializing: false })                          

        if (error.message == "ER_USER_NOT_FOUND")
            setForm({ ...form, error: true, errorText: "User not found." })
        else if (error.message == "ER_USER_EMAIL_TAKEN")
            setForm({ ...form, error: true, errorText: "Email is allready taken." })
        else
            setForm({ ...form, error: true, errorText: "An unknown error occurred." })                       

        // console.error(error)
    }
    
    const onChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
        
        let dataChanged = true        

        let formState = {...form}
        let data1 = {...data}

        if (typeof data1[id] == "boolean") {
            data1[id] = !data1[id]
        } else {
            data1[id] = value
        }
    
        formState.saveError = false
        formState.saveErrorText = ""

        if (data1.captegoClientId == "" && data1.captegoEnabled) {
            formState.captegoClientIdError = true
        } else {
            formState.captegoClientIdError = false
        }

        if (data1.captegoSecretKey== "" && data1.captegoEnabled) {
            formState.captegoSecretKeyError = true
        } else {
            formState.captegoSecretKeyError = false
        }

        if (data1.economicAgreementGrantToken == "" && data1.economicEnabled) {
            formState.economicAgreementGrantTokenError = true
        } else {
            formState.economicAgreementGrantTokenError = false
        }

        if (data1.ekompletAPIKey == "" && data1.ekompletEnabled) {
            formState.ekompletAPIKeyError = true
        } else {
            formState.ekompletAPIKeyError = false
        }

        if (data1.ekompletSiteName == "" && data1.ekompletEnabled) {
            formState.ekompletSiteNameError = true
        } else {
            formState.ekompletSiteNameError = false
        }

        if (data1.ordrestyringAPIKey == "" && data1.ordrestyringEnabled) {
            formState.ordrestyringAPIKeyError = true
        } else {
            formState.ordrestyringAPIKeyError = false
        }

        if (data1.syncroAPIKey == "" && data1.syncroEnabled) {
            formState.syncroAPIKeyError = true
        } else {
            formState.syncroAPIKeyError = false
        }

        if (data1.syncroSubdomain == "" && data1.syncroEnabled) {
            formState.syncroSubdomainError = true
        } else {
            formState.syncroSubdomainError = false
        }

        if (formState.captegoClientIdError || formState.captegoSecretKeyError || 
            formState.economicAgreementGrantTokenError || 
            formState.ekompletAPIKeyError || formState.ekompletSiteNameError || 
            formState.ordrestyringAPIKeyError ||
            formState.syncroAPIKeyError || formState.syncroSubdomainError) {
            formState.disabledSave = true
        } else {
            // formState.disabledSave = false

            // Validate: DATACHANGE
            if (JSON.stringify({...dataDefault}) === JSON.stringify({...data1}))
                dataChanged = false
        }

        formState.disabledSave = !(dataChanged)

        setForm(formState)
        setData(data1)
                
        // console.log ("onchange:"+ id +":"+ value)
        return true
    }

    const marks = [
        { value: 0, label: '0' },    
        { value: 2, label: '2' },    
        { value: 5, label: '5' },    
        { value: 15, label: '15' },
        { value: 30, label: '30' },
        { value: 60, label: '60' }
    ]
    
    return (
        
        <React.Fragment>
            {form.isInitializing ? 
                <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center',   justifyContent: 'center',}}>
                    <CircularProgress style={{width: '50px', height: '50px'}}/>
                </Box>   
            :
            <React.Fragment>
               
                <Paper elevation={3} sx={{ p: 2, mt: 0}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Captego API
                            </Typography>
                        </Grid>    
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch id="captegoEnabled" checked={data.captegoEnabled} onChange={onChange} disabled={form.disabled}/>} label="" labelPlacement="start" />
                                </FormGroup>
                        </Grid>
                    </Grid>
                    <TextField               
                        required                    
                        margin="dense"
                        id="captegoClientId"
                        label="clientId"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.captegoClientId}
                        error={form.captegoClientIdError}                        
                        disabled={form.disabled || !data.captegoEnabled}
                    />                             
                    <TextField      
                        required              
                        margin="dense"
                        id="captegoSecretKey"
                        label="secretKey"            
                        fullWidth                    
                        variant="standard"
                        autoComplete="new-password"
                        onChange={onChange}
                        value={data.captegoSecretKey}
                        error={form.captegoSecretKeyError}                        
                        disabled={form.disabled || !data.captegoEnabled}
                    />          
                    
                    <Collapse in={connectionStatus.captego}>
                        <Alert severity="success" sx={{mt: 1}}>Connected</Alert>            
                    </Collapse>   
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Economic API
                            </Typography>
                        </Grid>    
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch id="economicEnabled" checked={data.economicEnabled} onChange={onChange} disabled={form.disabled}/>} label="" labelPlacement="start" />
                                </FormGroup>
                        </Grid>
                    </Grid>                                    
                    <TextField      
                        required      
                        margin="dense"
                        id="economicAgreementGrantToken"
                        label="agreementGrantToken"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.economicAgreementGrantToken}
                        error={form.economicAgreementGrantTokenError}                        
                        disabled={form.disabled || !data.economicEnabled}
                    />         
                                                                                
                    <Collapse in={connectionStatus.economic}>
                        <Alert severity="success" sx={{mt: 1}}>Connected</Alert>            
                    </Collapse> 
                    <br></br>
                    <Link rel="noopener" target="_blank" href="https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=MDMtxwBw02S8vmyZSK0v0gO5yUjLqxKxYrkgDzBsrgI1">Click here to approve Synk in your e-conomic account.</Link>
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                EKomplet API
                            </Typography>
                        </Grid>    
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch id="ekompletEnabled" checked={data.ekompletEnabled} onChange={onChange} disabled={form.disabled}/>} label="" labelPlacement="start" />
                                </FormGroup>
                        </Grid>
                    </Grid>                                   
                    <TextField      
                        required      
                        margin="dense"
                        id="ekompletAPIKey"
                        label="ekompletAPIKey"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.ekompletAPIKey}
                        error={form.ekompletAPIKeyError}                        
                        disabled={form.disabled || !data.ekompletEnabled}
                    />         
                    <TextField      
                        required      
                        margin="dense"
                        id="ekompletSiteName"
                        label="ekompletSiteName"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.ekompletSiteName}
                        error={form.ekompletSiteNameError}
                        helperText={form.ekompletSiteNameErrorText}
                        disabled={form.disabled || !data.ekompletEnabled}
                    />
                                                                                
                    <Collapse in={connectionStatus.ekomplet}>
                        <Alert severity="success" sx={{mt: 1}}>Connected</Alert>            
                    </Collapse> 
                    <br></br>                    
                </Paper> 
                <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Ordrestyring API
                            </Typography>
                        </Grid>    
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch id="ordrestyringEnabled" checked={data.ordrestyringEnabled} onChange={onChange} disabled={form.disabled}/>} label="" labelPlacement="start" />
                                </FormGroup>
                        </Grid>
                    </Grid>                                    
                    <TextField      
                        required      
                        margin="dense"
                        id="ordrestyringAPIKey"
                        label="ordrestyringAPIKey"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.ordrestyringAPIKey}
                        error={form.ordrestyringAPIKeyError}                        
                        disabled={form.disabled || !data.ordrestyringEnabled}
                    />                                                                                                             
                    <Collapse in={connectionStatus.ordrestyring}>
                        <Alert severity="success" sx={{mt: 1}}>Connected</Alert>            
                    </Collapse> 
                    <br></br>                    
                </Paper> 
                <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Syncro API
                            </Typography>
                        </Grid>    
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch id="syncroEnabled" checked={data.syncroEnabled} onChange={onChange} disabled={form.disabled}/>} label="" labelPlacement="start" />
                                </FormGroup>
                        </Grid>
                    </Grid>                                   
                    <TextField      
                        required      
                        margin="dense"
                        id="syncroAPIKey"
                        label="syncroAPIKey"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.syncroAPIKey}
                        error={form.syncroAPIKeyError}                        
                        disabled={form.disabled || !data.syncroEnabled}
                    />         
                    <TextField      
                        required      
                        margin="dense"
                        id="syncroSubdomain"
                        label="syncroSubdomain"            
                        fullWidth                    
                        variant="standard"                    
                        onChange={onChange}
                        value={data.syncroSubdomain}
                        error={form.syncroSubdomainError}
                        helperText={form.syncroSubdomainErrorText}
                        disabled={form.disabled || !data.syncroEnabled}
                    />
                                                                                
                    <Collapse in={connectionStatus.syncro}>
                        <Alert severity="success" sx={{mt: 1}}>Connected</Alert>            
                    </Collapse>
                    <br></br>                    
                </Paper>
                {/* <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Title Template
                    </Typography>
                    <TextField      
                        required   
                        margin="dense"
                        id="titleTemplate"
                        label="Title Template"            
                        fullWidth                    
                        variant="standard"
                        autoComplete="new-password"
                        onChange={onChange}        
                        value={data.captegoTitleTemplate}            
                        disabled={form.disabled}
                    />                      
                </Paper> */}
                <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Sync interval
                    </Typography>
                    <Grid container>                        
                        <Grid item xs={12}>
                            <Slider 
                                name="interval"                                       
                                valueLabelDisplay="auto"
                                step={1}
                                marks={marks}
                                value={data.interval}
                                min={0}
                                max={60}
                                onChange={onChange}
                                disabled={form.disabledSync || form.disabled}
                            />                
                        </Grid>                   
                    </Grid>
                </Paper>
                
                {/* <Paper elevation={3} sx={{ p: 2, mt: 4}}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Inspection presets
                    </Typography>
                    <FormControl  
                        required
                        margin="dense" 
                        fullWidth 
                        variant="standard"                                 
                        disabled={form.disabledPreset}
                    >
                        <InputLabel>Project</InputLabel>
                        <Select name="projectId" value={data.projectId} onChange={onChange}>                                    
                            {optionsProjectId.map((option, index) => (
                                <MenuItem value={option.id}>{option.title}</MenuItem>
                            ))}
                        </Select>                   
                    </FormControl>
                    <FormControl  
                        required
                        margin="dense" 
                        fullWidth 
                        variant="standard"      
                        disabled={form.disabledPreset}
                    >
                        <InputLabel>Template</InputLabel>
                        <Select name="templateId" value={data.templateId} onChange={onChange}>                                    
                            {optionsTemplateId.map((option, index) => (
                                <MenuItem key={index} value={option.id}>{option.title}</MenuItem>
                            ))}
                        </Select>                   
                    </FormControl>
                    <FormControl  
                        required
                        margin="dense" 
                        fullWidth 
                        variant="standard"      
                        disabled={form.disabledDataKeyId}
                    >
                        <InputLabel>DataKey for complete</InputLabel>
                        <Select name="dataKeyId" value={data.dataKeyId} onChange={onChange}>                                    
                            {optionsDateKeyId.map((option, index) => (
                                <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                            ))}
                        </Select>                   
                    </FormControl>
                </Paper> */}
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3}}>
                    <Stack direction="row" spacing={2}>                        
                    
                        <LoadingButton variant="contained" color="primary" loading={form.loadingForceSync} disabled={form.disabledForceSync || form.disabled} onClick={handleForceSync}>
                            Force sync
                        </LoadingButton>       

                        <LoadingButton variant="contained" color="success" loading={form.loadingSave} disabled={form.disabledSave || form.disabled} onClick={handleSave}>
                            Save
                        </LoadingButton>       
                    </Stack>                   
                </Box>
            </React.Fragment>}
        </React.Fragment>     
    )
}

function main() {
    return (        
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100%)',
                width: '100%'
            }}
        >   
            <Grid container sx={ { height: '100%' }}>
                <Grid item xs={12}>
                    <Settings />
                </Grid>            
            </Grid>                      
        </Paper>
    )
}

export default main