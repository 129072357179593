// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Collapse,    
    Typography,
    Alert,
    Avatar, 
    Link,
    TextField,
    IconButton,
    Chip,
    Autocomplete
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI LAB ----
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------

import CircularProgress from '@mui/material/CircularProgress';
function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

export default (props) => {   
    
    // const fixedOptions = [...props.fixedOptions]
    // const fixedOptions = props.fixedOptions
    // const top100Films = [
    //     { label: 'User', key: "1" },  
    //     { label: 'Administrator', key: "2" },  
    // ]

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const fixedOptions = []
    

    const val1 = fixedOptions.concat(props.value)
    // const v2 = v.filter((c, index) => {
    //     return v.indexOf(c) === index;
    // });
    const val2 = val1.filter((v,i,a)=>a.findIndex(v2=>(v2[props.optionsKeyField]===v[props.optionsKeyField]))===i)

    // const [value, setValue] = React.useState([...fixedOptions])
    const [value, setValue] = React.useState(val2)

    const onChange = (e, value) => {
        let event = {target: {id: props.id, name: props.name, value: value}}

        if (typeof props.onChange === "function")
            props.onChange(event)
    }

    React.useEffect(() => {
        let active = true
    
        if (typeof props.options === "function") {
            if (!loading) {
                return undefined;
            }
            
            props.options ()
                .then((data) => {            
                    if (active) {
                        setOptions([...data])
                    }
                })
                
            return () => {
                active = false
            }
        } else {
            setOptions([...props.options])
        }
        
    }, [loading]);
    
    React.useEffect(() => {
        if (!open) {
            setOptions([])
        }
    }, [open])

    return (    
        <Autocomplete            
            value={value}    
            multiple                
            onChange={(event, newValue) => {
                let tempValue = [
                    ...fixedOptions,
                    ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
                ]
                
                setValue(tempValue)        
                onChange (event, tempValue)
            }}

            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
        
            options={options}
            loading={loading}
            // options={props.options}
            getOptionLabel={(option) => option[props.optionsLabelField]}
            isOptionEqualToValue={(option, value) => option[props.optionsKeyField] === value[props.optionsKeyField]}
            
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option[props.optionsLabelField]}
                        {...getTagProps({ index })}
                        disabled={fixedOptions.indexOf(option) !== -1}
                    />
                ))
            }      
            renderInput={(params) => (
                <TextField {...params} label={props.label} 
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                   />
            )}
        />
    )
}
