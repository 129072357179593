// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Collapse,
    MenuItem,
    Box,
    Stack,
    Paper,
    Typography,
    Alert,
    Button,
    FormControl,
    TextField,
    InputLabel,
    Select,
    Grid,
    IconButton,
    Chip
} from '@mui/material/'

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,
    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { passwordStrength } from 'check-password-strength'
import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
import SimpleUIChipBox from '../../../components/chipBox'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import { useAuthState } from '../../../components/authProvider'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_USERS = "/api/users/"
// ----------------------------------------------------------------------

export default (props) => {
    const {drawerState, setDrawerState } = props
    const {accessToken } = useAuthState()

    const formStateDefault = {
        usernameError: false,
        usernameErrorText: "",        
        passwordError: false,
        passwordErrorText: "",        
        passwordverifyError: false,
        passwordverifyErrorText: "",        
        emailError: false,
        emailErrorText: "",
        createDisabled: true,
        createLoading: false,
        error: false,    
        errorText: "",        
    }

    const formDataDefault = {
        username: "",        
        password: "",
        passwordverify: "",        
        sendactivationlink: false,
        name: "",
        email: "",
        permissionlevel: 1,
        usergroups: []        
    }
    
    const [formState, setFormState] = useState(formStateDefault)
    const [formData, setFormData] = useState(formDataDefault)

    const handleError = (error) => {
        if (error.message == "ER_USER_USERNAME_TAKEN")
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Username is allready taken." })
        else if (error.message == "ER_USER_EMAIL_TAKEN")
            setFormState({ ...formState, createLoading: false, error: true, errorText: "Email is allready taken." })
        else
            setFormState({ ...formState, createLoading: false, error: true, errorText: "An unknown error occurred." })

        // console.error(error)
    }

    const handleClose = (event, reason) => {
        setFormState(formStateDefault)
        setFormData(formDataDefault)
        setDrawerState({...drawerState, open: false})
    }

    const handleCreate = () => {
        setFormState({ ...formState, createLoading: true })

        fetch(API_URL_USERS, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify(formData),
        })
        .then((data) => {
            if (data.ok) {                
                props.refresh ()
                handleClose ()                               
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null) {
                if (data.error != null)
                    throw new Error (data.error.code)                
                else 
                    throw new Error ("ER_UNKNOWN")
            }
        })
        .catch((error) => {        
            handleError (error)            
        })            
    }

    const onChange = (event, value1) => {
        const id = (event.target.id || event.target.name)        
        let value = event.target.value

        let usernameIsValid = false
        let emailIsValid = false
        let passwordIsValid = false
        let passwordIsMatch = false

        let newFormState = {...formState}

        newFormState.createError = false
        newFormState.createErrorText = ""

        if (event.target.id == "sendactivationlink")
            value = event.target.checked
        
        // Validate: USERNAME
        if ({ ...formData, [id]: value }.username.length >= 3) {
            usernameIsValid = true
            newFormState.usernameErrorText = ""
            newFormState.usernameError = false
        } else if ({ ...formData, [id]: value }.username.length > 0) {            
            newFormState.usernameErrorText = "Username is to short"
            newFormState.usernameError = true
        }

        // Validate: EMAIL
        if (emailValidator.validate({ ...formData, [id]: value }.email)) {
            emailIsValid = true
            newFormState.emailErrorText = ""
            newFormState.emailError = false
        } else if ({ ...formData, [id]: value }.email.length > 0) {            
            newFormState.emailErrorText = "E-Mail is not valid"
            newFormState.emailError = true
        }

        // Validate: PASSWORD STRENGTH
        if (passwordStrength({ ...formData, [id]: value }.password).value != "Too weak") {
            passwordIsValid = true
            newFormState.passwordErrorText = ""
            newFormState.passwordError = false
        } else if ({ ...formData, [id]: value }.password.length > 0) {
            newFormState.passwordErrorText = "Password is to weak"
            newFormState.passwordError = true
        }

        // Validate: PASSWORD MATCH
        if ({ ...formData, [id]: value }.password == { ...formData, [id]: value }.passwordverify) {
            passwordIsMatch = true        
            newFormState.passwordverifyErrorText = ""
            newFormState.passwordverifyError = false    
        } else if ({ ...formData, [id]: value }.password.length > 0) {
            newFormState.passwordverifyErrorText = "Passwords does not match"
            newFormState.passwordverifyError = true    
        }

        if ({ ...formData, [id]: value }.sendactivationlink) {
            passwordIsValid = true
            passwordIsMatch = true
        }
        
        newFormState.createDisabled = !(usernameIsValid && emailIsValid && passwordIsValid && passwordIsMatch)        
        
        // console.log ("onchange:"+ id +":"+ value)

        setFormState(newFormState)
        setFormData({ ...formData, [id]: value })        
        
        return true
    }

    const usergroupOptionsFixed = [
        { name: 'Users', id: "b7d9fde2-f1ea-4376-ae58-d99e30e446f7" },          
    ];

    const usergroupOptions = async () => {
        let data = await fetch("/api/usergroups/", {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
        })

        return data.json()
    }

    return (
        <Grid container spacing={2}>                        
            <Grid item xs={12}>
                <Collapse in={formState.error}>
                    <Alert variant="filled" severity="error" 
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setFormState({ ...formState, error: false })
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {formState.errorText}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>                    
                <Paper elevation={3} sx={{ p: 2}}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Login
                    </Typography>
                    <TextField               
                        required     
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Username"            
                        fullWidth                    
                        variant="standard"
                        autoComplete="new-password"
                        onChange={onChange}
                        error={formState.usernameError}
                        helperText={formState.usernameErrorText}
                    />                                        
                    <Collapse in={!formData.sendactivationlink}>
                    <TextField      
                        required              
                        margin="dense"
                        id="password"
                        label="Password"            
                        fullWidth
                        type="password"
                        variant="standard"
                        autoComplete="new-password"
                        onChange={onChange}
                        error={formState.passwordError}
                        helperText={formState.passwordErrorText}
                    />         
                    <TextField                    
                        required
                        margin="dense"
                        id="passwordverify"
                        label="Verify Password"            
                        type="password"
                        fullWidth
                        variant="standard"
                        autoComplete="new-password"                            
                        onChange={onChange}
                        error={formState.passwordverifyError}
                        helperText={formState.passwordverifyErrorText}
                    />
                    </Collapse>
                    <FormGroup sx={{pt: 1}}>
                        <FormControlLabel control={<Checkbox id="sendactivationlink" checked={formData.sendActivationLink} onChange={onChange}/>} label="Send activation link" />
                    </FormGroup>  
                </Paper>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2}}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Details
                    </Typography>
                    <TextField                    
                        margin="dense"
                        id="name"
                        label="Name"            
                        fullWidth
                        variant="standard"  
                        onChange={onChange}                  
                    />
                    <TextField       
                        required             
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        onChange={onChange}                    
                        error={formState.emailError}
                        helperText={formState.emailErrorText}            
                    /> 
                </Paper> 
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2}}>
                    <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                        Access
                    </Typography>
                    <FormControl
                        margin="dense" 
                        fullWidth 
                        variant="standard"                                 
                    >
                    <SimpleUIChipBox name="usergroups" onChange={onChange} value={formData.usergroups} options={usergroupOptions} fixedOptions={usergroupOptionsFixed} label={"Usergroups"} optionsLabelField={'name'} optionsKeyField={'id'}></SimpleUIChipBox>
                    </FormControl>
                </Paper>
            </Grid>           
            <Grid itme xs={6}>
                
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" onClick={handleClose}>Close</Button>
                        <LoadingButton variant="contained" color="success" loading={formState.createLoading} disabled={formState.createDisabled} onClick={handleCreate}>
                            Create
                        </LoadingButton>       
                    </Stack>                   
                </Box>
            </Grid>
        </Grid>
    )
}
