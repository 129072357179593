import React from 'react'
import ReactDOM from 'react-dom'

import './simpleUI/index.css'

import {
    SimpleUIApp,

    SimpleUIPageUsers
} from './simpleUI'

import CaptegoSync from "./pages/captegosync"
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const pages = [
    {
        path: "/",
        Main: CaptegoSync,
        sidebarnav: {
            icon: SyncAltIcon,
            label: "CaptegoSync"
        }
    },
    SimpleUIPageUsers,
]

const settings = {
    useAuthentication: true,
    useEmailAsUsername: false,
    hideAppBar: false,
    hideNavBar: false
}

ReactDOM.render(
    <React.StrictMode>
        <SimpleUIApp pages={pages} settings={settings}/>        
    </React.StrictMode>,
    document.getElementById('root')
)