import React, { useReducer } from "react";
import jwt from 'jwt-decode'

// let user = localStorage.getItem("currentSession")
//   ? JSON.parse(localStorage.getItem("currentSession")).user
//   : ""

// let accessToken = localStorage.getItem("currentSession")
//   ? JSON.parse(localStorage.getItem("currentSession")).accessToken
//   : ""

// let refreshToken = localStorage.getItem("currentSession")
//   ? JSON.parse(localStorage.getItem("currentSession")).refreshToken
//   : ""

// if (accessToken != null && accessToken != "")
// {
//     const test = jwt(accessToken)
//     let currentDate = new Date();
//     if (test.exp * 1000 < currentDate.getTime()) {
//         console.log("Token expired.");
//         user = ""
//         accessToken = ""
//         refreshToken = ""
//     } else {
//         console.log("Valid token");       
//     }
// }

  

//   console.log (token)
//   console.log (JSON.parse(localStorage.getItem("currentUser")).accessToken)
  
// console.log ((accessToken == "") ? false : true)
 
export const initialState = {
    // initializing: (accessToken == "") ? false : true,
    authInitializing: false,
    authorized: false,
    user: "",
    accessToken: "",  
    refreshToken: "",
}

// console.log (initialState)
 
export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "AUTHORIZED":
        return {
            ...initialState,
            authorized: true,
            authInitializing: false,
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
            user: action.payload.user
        }

    case "REQUEST_LOGIN":
        return {
            ...initialState,
            loading: true
        }

    case "SIGNIN_SUCCESS":
        console.log (action.payload)
        return {
            ...initialState,
            authorized: true,
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
            user: action.payload.user,
        }

    case "UNAUTHORIZE":
        return {
            ...initialState, 
            authorized: false,           
            accessToken: "",
            refreshToken: "",            
            user: ""            
        }    

    case "TOKEN_EXPIRED":
        return {
            ...initialState,
            user: "",
            accessToken: "",
            refreshToken: ""
        }         
    
    case "TOKEN_REFRESH_SUCCESS":
        console.log (action.payload)
        return {
            ...initialState,            
            authorized: true,
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
            user: action.payload.user,
        }   
 
    default:
       throw new Error(`Unhandled action type: ${action.type}`);
  }
}