// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Grid,
    Paper,
    Typography,
    Stack,        
    Button,
    IconButton,
    TextField,
    InputLabel,
    Select,
    FormControl,
    Collapse,
    MenuItem,
    Alert,
    CircularProgress
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import {
    LoadingButton,    
} from '@mui/lab/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Close as CloseIcon,    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import emailValidator from 'email-validator'
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
import { useAuthState } from '../../../components/authProvider'
import SimpleUIChipBox from '../../../components/chipBox'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------

// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
const API_URL_USERS = "/api/users/"
// ----------------------------------------------------------------------

export default (props) => {
    const {refresh, userId, drawerState, setDrawerState } = props

    const {accessToken } = useAuthState()

    const formStateDefault = {
        isInitializing: false,
        disabled: false,                              
        mainButtonDisabled: true,
        mainButtonLoading: false,
        error: false,    
        errorText: "",
        errorEmail: false,
        errorEmailText: "",        
    }

    const formValueDefault = {
        username: "",        
        password: "",
        passwordverify: "",
        name: "",
        email: "",
        usergroups: [],
        permissionlevel: 1
    }
        
    const [formState, setFormState] = useState(formStateDefault)   
    const [formData, setFormData] = useState(formValueDefault)
    const [defaultData, setDefaultData] = useState({})
    
    useEffect(() => {
        if (drawerState.open && userId != "" && userId != null) {
            setFormState({ ...formState, isInitializing: true })
            fetch(API_URL_USERS + userId, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           
            .then(data=>{                
                return data.json ()
            })                
            .then((data) => {                            
                if (data.error == null) {
                    setDefaultData(data)
                    setFormData(data)          
                    setFormState({ ...formState, isInitializing: false })
                } else {              
                    throw new Error (data.error.code)                
                }
            })            
            .catch((error) => {
                handleError (error)
            })        
        }                
    }, [drawerState.open])

    const handleSave = () => {
        setFormState({ ...formState, mainButtonLoading: true })

        fetch(API_URL_USERS + userId, {
            method: 'PATCH', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
            body: JSON.stringify(formData),
        })
        .then((data) => {
            if (data.ok) {                
                refresh ()
                handleClose ()                               
            } else {
                return data.json ()            
            }
        })        
        .then((data) => {
            if (data != null)
                throw new Error (data.error.code)                
        })
        .catch((error) => {       
            handleError (error)
        })            
    }

    const handleOnChange = (event) => {
        const id = (event.target.id || event.target.name)        
        const value = event.target.value
        
        let dataChanged = true
        let emailIsValid = false        

        let newFormState = {...formState}

        newFormState.error = false
        newFormState.errorText = ""
                
        // Validate: DATACHANGE
        if (JSON.stringify({...defaultData}) === JSON.stringify({ ...formData, [id]: value }))
            dataChanged = false

        // Validate: EMAIL
        if (emailValidator.validate({ ...formData, [id]: value }.email)) {
            emailIsValid = true
            newFormState.errorEmail = false
            newFormState.errorEmailText = ""            
        } else if ({ ...formData, [id]: value }.email.length > 0) {            
            newFormState.errorEmail = true
            newFormState.errorEmailText = "E-Mail is not valid"            
        }
                
        newFormState.mainButtonDisabled = !(dataChanged && emailIsValid)

        setFormState(newFormState)
        setFormData({ ...formData, [id]: value })
        return true
    }

    const handleError = (error) => {
        setFormState({ ...formState, isInitializing: false })                          

        if (error.message == "ER_USER_NOT_FOUND")
            setFormState({ ...formState, error: true, errorText: "User not found." })
        else if (error.message == "ER_USER_EMAIL_TAKEN")
            setFormState({ ...formState, error: true, errorText: "Email is allready taken." })
        else
            setFormState({ ...formState, error: true, errorText: "An unknown error occurred." })     
    }

    const handleClose = (event, reason) => {
        setFormState(formStateDefault)
        setFormData(formValueDefault)
        
        setDrawerState({drawerState, open: false})
    }

    const usergroupOptionsFixed = [
        { name: 'Users', id: "b7d9fde2-f1ea-4376-ae58-d99e30e446f7" },          
    ];


    const usergroupOptions = async () => {
        let data = await fetch("/api/usergroups/", {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer "+ accessToken
            },
        })

        return data.json()
    }

    return (
        <React.Fragment>
            {formState.isInitializing ? 
                <Box style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                    <CircularProgress style={{width: '50px', height: '50px'}}/>
                </Box>                  
            :                         
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Collapse in={formState.error}>
                            <Alert variant="filled" severity="error" 
                                action={
                                    <IconButton aria-label="close" color="inherit" size="small"
                                        onClick={() => {
                                            setFormState({ ...formState, error: false })
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {formState.errorText}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 2}}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Login
                            </Typography>
                            <TextField                                                                               
                                margin="dense"
                                id="username"
                                label="Username"            
                                fullWidth                    
                                variant="standard"
                                autoComplete="new-password"
                                onChange={handleOnChange}
                                error={formState.usernameError}
                                helperText={formState.usernameErrorText}
                                value={formData.username}
                                disabled
                            />                                                         
                        </Paper>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 2}}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Details
                            </Typography>
                            <TextField                    
                                margin="dense"
                                id="name"
                                label="Name"            
                                fullWidth
                                variant="standard"  
                                onChange={handleOnChange}                  
                                value={formData.name}
                                disabled={formState.disabled}
                            />
                            <TextField       
                                required             
                                margin="dense"
                                id="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="standard"
                                autoComplete="off"
                                onChange={handleOnChange}                    
                                error={formState.errorEmail}
                                helperText={formState.errorEmailText}
                                value={formData.email}
                                disabled={formState.disabled}
                            /> 
                        </Paper> 
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 2}}>
                            <Typography variant="button" display="block"  style={{ fontWeight: 600 }}>
                                Access
                            </Typography>
                            <FormControl
                                margin="dense" 
                                fullWidth 
                                variant="standard"                                 
                            >
                            <SimpleUIChipBox name="usergroups" onChange={handleOnChange} value={formData.usergroups} options={usergroupOptions} fixedOptions={usergroupOptionsFixed} label={"Usergroups"} optionsLabelField={'name'} optionsKeyField={'id'}></SimpleUIChipBox>
                            </FormControl>
                        </Paper>
                    </Grid>  
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={handleClose}>Close</Button>
                                <LoadingButton variant="contained" color="success" loading={formState.mainButtonLoading} disabled={formState.mainButtonDisabled} onClick={handleSave}>
                                    Save
                                </LoadingButton>       
                            </Stack>                   
                        </Box>
                    </Grid>
                </Grid>   
            }    
        </React.Fragment>
    )
}
