// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import { DataGrid } from '@mui/x-data-grid'
import ClickAwayListener from '@mui/base/ClickAwayListener'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------

function Listview(props) {        
    const {columns, rows, rowsPerPageOptions, onSelection, onClickAway, selectionModel} = props
    // const [selectionModel, setSelectionModel] = React.useState([])    

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <DataGrid                        
                columns={columns}        
                rows={rows}
                rowsPerPageOptions={rowsPerPageOptions}
                disableColumnMenu                                    
                onSelectionModelChange={onSelection}
                selectionModel={selectionModel}                        
            />
        </ClickAwayListener>
    )
}

export default Listview